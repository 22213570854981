import { fetchData, upLoadToOss } from '@/api'
import getFileInfo from '../getFileInfo/index.js'

/**
 * 获取上传文件的Token
 */
const getUploadToken = async () => {
	return await fetchData({
		url: '/get_uptoken.cls.php'
	})
}

/**
 * @typedef TData
 * @property {string} url 文件网络地址
 * @property {string} filename 文件名
 * @property {object} fileInfo 文件对象
 */

/**
 * @typedef TResponse 响应结果
 * @property {-1|0|1} code 响应状态码 -1 登录过期, 0 请求正常, 1 请求有误
 * @property {string} msg 请求结果描述
 * @property {TData}  data 请求结果内容
 */

/**
 * 将文件上传到阿里OSS
 * @param {File} file 文件对象
 * @param {string} [name] 文件名称, 若为空将自动计算 [可选]
 * @param {import('@/types/UpLoadToOss').Options} [options] 上传配置选项 [可选]
 * @returns {Promise<TResponse>}
 */
export default async (file, name, options = {}) => {
	const { data } = await getUploadToken()
	let filename = null
	let fileInfo = null
	if (name === void 0 || name === null) {
		fileInfo = await getFileInfo(file)
		filename = `${fileInfo.hash}t${Date.now()}${fileInfo.suffix}`
	} else {
		filename = name
	}
	const result = await upLoadToOss(data, file, filename, options)
	return {
		code: 0,
		msg: '上传成功',
		data: {
			url: result.url,
			filename,
			fileInfo
		}
	}
}
