<template>
	<span
		class="um-tag-container"
		:style="{ '--um-type': type, backgroundColor: type, padding, fontSize, borderRadius }"
	>
		<slot></slot>
		<span
			v-if="close"
			class="um-close-icon"
			@mouseenter="hover = true"
			@mouseleave="hover = false"
			@click="$emit('close')"
		>
			<span class="um-icon" :class="iconClass"></span>
		</span>
	</span>
</template>

<script>
export default {
	props: {
		type: {
			type: String,
			default: '#409EFF'
		},

		size: {
			type: String,
			default: 'normal'
		},

		close: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			hover: false
		}
	},

	computed: {
		padding() {
			const map = {
				mini: '2px 4px',
				small: '3px 6px',
				normal: '4px 8px',
				large: '5px 10px'
			}
			return map[this.size] || map['normal']
		},

		fontSize() {
			const map = {
				mini: '12px',
				small: '13px',
				normal: '14px',
				large: '15px'
			}
			return map[this.size] || map['normal']
		},

		borderRadius() {
			const map = {
				mini: '2px',
				small: '2px',
				normal: '2px',
				large: '2px'
			}
			return map[this.size] || map['normal']
		},

		iconClass() {
			return this.hover ? 'el-icon-circle-close' : 'el-icon-error'
		}
	}
}
</script>

<style scoped lang="scss">
.um-tag-container {
	display: inline-block;
	width: fit-content;
	color: #fff;
	box-sizing: border-box;
	line-height: 1.5;
}

.um-close-icon {
	display: inline-flex;
	justify-content: center;
	align-items: center;
	margin-left: 3px;
	cursor: pointer;
}
</style>
