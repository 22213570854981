import getSuffix from '../getSuffix/index'
import createHash from './createHash'

/**
 * @typedef TFileInfo
 * @property {string} suffix 文件后缀
 * @property {string} hash 文件 hash
 * @property {File} file 传入的文件对象
 */

/**
 * 获取文件信息
 * @param {File} file File对象
 * @returns {Promise<TFileInfo>}
 */
export default async (file) => {
	if (!(file instanceof File || file instanceof Blob)) {
		throw new TypeError('file 必须是一个 File 对象')
	}
	const suffix = getSuffix(file.name)
	const hash = await createHash(file)
	return {
		suffix,
		hash,
		file
	}
}
