export { uploadOSS } from '@/utils'
import { fetchData } from '@/api'

/**
 * 获取unc的详细信息
 * @param {string} ucn 统一ID
 * - 10020056184 测试统一ID
 */
export const getUcnInfo = (ucn) =>
	fetchData({
		url: '/get_ucnInfo.cls.php',
		data: {
			UCN: ucn
		}
	})

/**
 * 获取管理员权限详情(展示已有的权限)
 */
export const getAdminPower = (data) =>
	fetchData({
		url: '/get_adminPower.cls.php',
		data
	})

/**
 * 获取管理员权限id列表(用于修改管理员时还原选项)
 */
export const getAdminPowerId = (data) =>
	fetchData({
		url: '/get_adminPowerId.cls.php',
		data
	})

/** 获取权限选择器 */
export const getPowerSelect = (data) =>
	fetchData({
		url: '/get_powerSelect.cls.php',
		data
	})

/** 获取管理员列表 */
export const getAdminList = (data) =>
	fetchData({
		url: '/get_adminList.cls.php',
		data
	})

/** 获取个人信息 */
export const getMeInfo = (data) =>
	fetchData({
		url: '/get_myInformation.cls.php',
		data
	})

/** 更新个人信息 */
export const updateMeInfo = (data) =>
	fetchData({
		url: '/update_myInformation.cls.php',
		data
	})

/** 创建管理员 */
export const createAdmin = (data) =>
	fetchData({
		data,
		url: '/create_adminInfo.cls.php'
	})

/** 修改管理员 */
export const updateAdmin = (data) =>
	fetchData({
		url: '/update_adminInfo.cls.php',
		data
	})

/** 删除管理员 */
export const deleteAdmin = (data) =>
	fetchData({
		url: '/delete_adminInfo.cls.php',
		data
	})

/** 获取平台选择器 */
export const getPlatformSelect = (data) =>
	fetchData({
		url: '/get_platformSelect.cls.php',
		data
	})

/** 获取成员选择器 */
export const getMemberSelect = (data) =>
	fetchData({
		url: '/get_memberSelect.cls.php',
		data
	})

/** 获取身份类型选择器 */
export const getIdentitySelect = (data) =>
	fetchData({
		url: '/get_memTypeSelect.cls.php',
		data
	})

/** 创建项目 */
export const createProject = (data) =>
	fetchData({
		url: '/create_projectInfo.cls.php',
		data
	})

/** 获取项目列表 */
export const getProjectList = (data) =>
	fetchData({
		url: '/get_projectList.cls.php',
		data
	})

/** 修改项目 */
export const updateProject = (data) =>
	fetchData({
		url: '/update_projectInfo.cls.php',
		data
	})

/** 删除项目 */
export const deleteProject = (data) =>
	fetchData({
		url: '/delete_projectInfo.cls.php',
		data
	})

/** 获取项目详情 */
export const getProjectDetail = (data) =>
	fetchData({
		url: '/get_projectInfo.cls.php',
		data
	})

/** 创建人员 */
export const createPersonnel = (data) =>
	fetchData({
		url: '/create_memberInfo.cls.php',
		data
	})

/** 删除人员 */
export const deletePersonnel = (data) =>
	fetchData({
		url: '/delete_memberInfo.cls.php',
		data
	})

/** 修改人员 */
export const updatePersonnel = (data) =>
	fetchData({
		url: '/update_memberInfo.cls.php',
		data
	})

/** 获取人员列表 */
export const getPersonnelList = (data) =>
	fetchData({
		url: '/get_memberList.cls.php',
		data
	})

/** 创建身份类型 */
export const createIdentityType = (data) =>
	fetchData({
		url: '/create_memTypeInfo.cls.php',
		data
	})

/** 删除身份类型 */
export const deleteIdentityType = (data) =>
	fetchData({
		url: '/delete_memTypeInfo.cls.php',
		data
	})

/** 修改身份类型 */
export const updateIdentityType = (data) =>
	fetchData({
		url: '/update_memTypeInfo.cls.php',
		data
	})

/** 获取身份类型 */
export const getIdentityTypeList = (data) =>
	fetchData({
		url: '/get_memTypeList.cls.php',
		data
	})

/** 创建源码存档 */
export const createSourceCodeBackup = (data) =>
	fetchData({
		url: '/create_proArchiveInfo.cls.php',
		data
	})

/** 获取源码存档列表 */
export const getSourceCodeBackupList = (data) =>
	fetchData({
		url: '/get_proArchiveList.cls.php',
		data
	})

/** 获取指定源码详情 */
export const getSourceCodeBackupDetail = (data) =>
	fetchData({
		url: '/get_proArchiveInfo.cls.php',
		data
	})

/** 修改源码存档 */
export const updateSourceCodeBackup = (data) =>
	fetchData({
		url: '/update_proArchiveInfo.cls.php',
		data
	})

/** 获取平台列表 */
export const getPlatformList = (data) =>
	fetchData({
		url: '/get_proPlatList.cls.php',
		data
	})

/** 删除平台 */
export const deletePlatform = (data) =>
	fetchData({
		url: '/delete_proPlatInfo.cls.php',
		data
	})

/** 获取标签列表 */
export const getTagList = (data) =>
	fetchData({
		url: '/get_proTagList.cls.php',
		data
	})

/** 删除标签 */
export const deleteTag = (data) =>
	fetchData({
		url: '/delete_proTagInfo.cls.php',
		data
	})

/** 修改标签 */
export const updateTag = (data) =>
	fetchData({
		url: '/update_proTagInfo.cls.php',
		data
	})

/** 添加标签 */
export const createTag = (data) =>
	fetchData({
		url: '/create_proTagInfo.cls.php',
		data
	})

/** 获取接口目录 */
export const getApiDir = (data) =>
	fetchData({
		url: '/get_cataInterList.cls.php',
		data
	})

/** 创建接口根目录 */
export const createApiRootDir = (data) =>
	fetchData({
		url: '/create_rootCataInfo.cls.php',
		data
	})

/** 创建接口目录 */
export const createApiDir = (data) =>
	fetchData({
		url: '/create_subCataInfo.cls.php',
		data
	})

/** 删除接口目录 */
export const deleteApiDir = (data) =>
	fetchData({
		url: '/delete_proCataInfo.cls.php',
		data
	})

/** 创建接口 */
export const createApi = (data) =>
	fetchData({
		url: '/create_proInterfaceInfo.cls.php',
		data
	})

/** 获取接口详情 */
export const getApiDetail = (data) =>
	fetchData({
		url: '/get_interfaceInfo.cls.php',
		data
	})

/** 获取接口请求方式选择器 */
export const getApiMethodSelect = (data) =>
	fetchData({
		url: '/get_requestMethodSelect.cls.php',
		data
	})

/** 获取接口请求格式选择器 */
export const getApiRequestFormatSelect = (data) =>
	fetchData({
		url: '/get_requestFormat.cls.php',
		data
	})

/** 获取接口请求格式选择器 */
export const getApiResponseFormatSelect = (data) =>
	fetchData({
		url: '/get_responseFormat.cls.php',
		data
	})

/** 修改接口 */
export const updateApi = (data) =>
	fetchData({
		url: '/update_proInterfaceInfo.cls.php',
		data
	})

/** 修改目录 */
export const updateDir = (data) =>
	fetchData({
		url: '/update_proCataInfo.cls.php',
		data
	})

/** 获取项目成员列表 */
export const getProjectMemberList = (data) =>
	fetchData({
		url: '/get_proMemberList.cls.php',
		data
	})

/** 获取项目成员选择器(除去项目已有成员) */
export const getProjectMemberSelect = (data) =>
	fetchData({
		url: '/get_proMemSelect.cls.php',
		data
	})

/** 创建项目成员 */
export const createProjectMember = (data) =>
	fetchData({
		url: '/create_projectMemBind.cls.php',
		data
	})

/** 删除项目成员 */
export const deleteProjectMember = (data) =>
	fetchData({
		url: '/delete_proMemberInfo.cls.php',
		data
	})

/** 修改项目成员 */
export const updateProjectMember = (data) =>
	fetchData({
		url: '/update_proMemberInfo.cls.php',
		data
	})

/** 添加平台 */
export const createPlatform = (data) =>
	fetchData({
		url: '/create_proPlatInfo.cls.php',
		data
	})

/** 删除平台指定照片 */
export const deletePlatformImg = (data) =>
	fetchData({
		url: '/delete_platImgInfo.cls.php',
		data
	})

/** 添加指定平台照片 */
export const createPlatformImg = (data) =>
	fetchData({
		url: '/create_platImgInfo.cls.php',
		data
	})

/** 修改指定平台照片 */
export const updatePlatformImg = (data) =>
	fetchData({
		url: '/update_platImgInfo.cls.php',
		data
	})

/** 获取指定平台详情 */
export const getPlatformDetail = (data) =>
	fetchData({
		url: '/get_platDetailInfo.cls.php',
		data
	})

/** 修改指定平台基础信息 */
export const updatePlatform = (data) =>
	fetchData({
		url: '/update_proPlatInfo.cls.php',
		data
	})

/** 更新项目需求 */
export const updateDemand = (data) =>
	fetchData({
		url: '/update_proRequireInfo.cls.php',
		data
	})

/** 更新项目需求附件 */
export const updateDemandAnnex = (data) =>
	fetchData({
		url: '/update_proAnnex.cls.php',
		data
	})

/** 获取项目需求信息 */
export const getDemandInfo = (data) =>
	fetchData({
		url: '/get_demandInfo.cls.php',
		data
	})

/** 获取项目需求附件类型 */
export const getDemandFileTypeSelect = (data) =>
	fetchData({
		url: '/get_annexTypeSelect.cls.php',
		data
	})

/** 创建一个任务周期 */
export const createTaskCycle = (data) =>
	fetchData({
		url: '/create_proCycleInfo.cls.php',
		data
	})

/** 获取任务周期列表 */
export const getTaskCycleList = (data) =>
	fetchData({
		url: '/get_eventList.cls.php',
		data
	})

/** 修改指定任务周期 */
export const updateTaskCycle = (data) =>
	fetchData({
		url: '/update_cycleInfo.cls.php',
		data
	})

/** 获取周期进度选择器 */
export const getCycleSelect = (data) =>
	fetchData({
		url: '/get_progressSelect.cls.php',
		data
	})

/** 创建事件 */
export const createEvent = (data) =>
	fetchData({
		url: '/create_proEventInfo.cls.php',
		data
	})

/** 更新事件 */
export const updateEvent = (data) =>
	fetchData({
		url: '/update_eventInfo.cls.php',
		data
	})

/** 为事件添加标签 */
export const createEventTag = (data) =>
	fetchData({
		url: '/create_eventTagBind.cls.php',
		data
	})

/** 事件标签选择器 */
export const getEventTagSelect = (data) =>
	fetchData({
		url: '/get_eventTagSelect.cls.php',
		data
	})

/** 事件创建标签并添加 */
export const createEventTagAndAdd = (data) =>
	fetchData({
		url: '/create_eventTagInfo.cls.php',
		data
	})

/** 获取事件下标签列表 */
export const getEventTagList = (data) =>
	fetchData({
		url: '/get_eventTagList.cls.php',
		data
	})

/** 删除事件上的标签 */
export const deleteEventTag = (data) =>
	fetchData({
		url: '/delete_eventTagBind.cls.php',
		data
	})

/** 创建事项 */
export const createEventItem = (data) =>
	fetchData({
		url: '/create_proItemInfo.cls.php',
		data
	})

/** 获取事项上的标签 */
export const getEventItemTagList = (data) =>
	fetchData({
		url: '/get_itemTagList.cls.php',
		data
	})

/** 删除事项上的标签 */
export const deleteEventItemTag = (data) =>
	fetchData({
		url: '/delete_itemTagBind.cls.php',
		data
	})

/** 获取事项标签选择器 */
export const getEventItemTagSelect = (data) =>
	fetchData({
		url: '/get_itemTagSelect.cls.php',
		data
	})

/** 添加事项标签 */
export const createEventItemTag = (data) =>
	fetchData({
		url: '/create_itemTagBind.cls.php',
		data
	})

/** 事项创建标签并添加 */
export const createEventItemTagAndAdd = (data) =>
	fetchData({
		url: '/create_itemTagInfo.cls.php',
		data
	})

/** 修改事项 */
export const updateEventItem = (data) =>
	fetchData({
		url: '/update_itemInfo.cls.php',
		data
	})

/** 删除周期 */
export const deleteCycle = (data) =>
	fetchData({
		url: '/delete_cycleInfo.cls.php',
		data
	})

/** 删除事件 */
export const deleteEvent = (data) =>
	fetchData({
		url: '/delete_eventInfo.cls.php',
		data
	})

/** 删除事项 */
export const deleteEventItem = (data) =>
	fetchData({
		url: '/delete_itemInfo.cls.php',
		data
	})
