<template>
	<div class="um-upload-fold" ref="UmFold" :style="{ height: useHeight }">
		<slot></slot>
	</div>
</template>

<script>
export default {
	props: {
		state: {
			type: Boolean,
			default: false
		},

		firstTransition: {
			type: Boolean,
			default: true
		}
	},
	data() {
		return {
			useHeight: this.firstTransition ? '0px' : 'auto'
		}
	},

	methods: {
		getHeight() {
			const dom = this.$refs.UmFold
			let originH = window.getComputedStyle(dom).height
			dom.style.height = 'auto'
			let h = window.getComputedStyle(dom).height
			dom.style.height = originH
			window.getComputedStyle(dom).height
			return h
		}
	},

	watch: {
		state: {
			immediate: true,
			async handler(state) {
				await this.$nextTick()
				if (state) {
					const h = this.getHeight()
					this.useHeight = h
					return
				}
				this.useHeight = '0px'
			}
		}
	}
}
</script>

<style scoped lang="scss">
.um-upload-fold {
	--um-upload-fold-transition: 0.3s;
	overflow: hidden;
	transition: var(--um-upload-fold-transition);
}
</style>
