var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"um-move-container",style:({
		userSelect: _vm.userSelect,
		'--um-lineWidth': _vm.lineWidth,
		'--um-lineBackground': _vm.lineBackground,
		'--um-lineHoverBackground': _vm.lineHoverBackground,
		'--um-lineTransitionBackground': _vm.lineTransitionBackground
	})},[_vm._t("default"),_c('div',{staticClass:"um-line",on:{"mousedown":_vm.mousedown}},[_vm._t("line",function(){return [_c('div',{staticClass:"um-line-default"})]})],2)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }