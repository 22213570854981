import Vuex from 'vuex'
import Vue from 'vue'
// import { getUserSchoolAndCampusSelect } from '@/apiUtils'
import { init } from '@/components/common/powerMap' // 此处导入仅用于类型提示

Vue.use(Vuex)
const store = new Vuex.Store({
	state: {
		adminInfo: {
			regionSelect: [], // 当前管理员校区选择器
			region: '', // 当前校区(中文)
			regionId: '' // 当前校区id
		},
		/**
		 * @type {ReturnType<typeof init>} 权限映射表
		 * 1. **存在权限的键值返回对应权限的字符串**
		 * 2. **无权限则键值则返回 null**
		 */
		powerMap: {} // 权限映射表(用于精细化权限验证)
	},
	mutations: {
		/** 设置管理员校区信息 */
		// setAdminSchoolRegion(state, data) {
		// 	state.adminInfo.regionSelect = data.regionSelect
		// 	state.adminInfo.region = data.region
		// 	state.adminInfo.regionId = data.regionId
		// },

		setPowerMap(state, data) {
			state.powerMap = data
		}
	},
	actions: {
		/** 获取管理员校区信息 */
		// async getAdminSchoolRegion(ctx) {
		// 	const { data = [], region, id } = await getUserSchoolAndCampusSelect()
		// 	const info = {
		// 		regionSelect: data,
		// 		region: region,
		// 		regionId: String(id)
		// 	}
		// 	localStorage.setItem('command', region)
		// 	ctx.commit('setAdminSchoolRegion', info)
		// },

		setPowerMap(ctx, data) {
			ctx.commit('setPowerMap', data)
		}
	},
	modules: {}
})

export default store
