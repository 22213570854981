<template>
	<div>
		<el-button type="primary" @click="dialog = true" v-bind="$attrs">{{ title }}</el-button>

		<!-- 导出选择 -->
		<el-dialog title="导出数据" :visible.sync="dialog" width="35%">
			<div class="flex">
				<div v-for="item in list" :key="item.id" class="item mr20 mb20">
					<el-radio v-model="state" :label="item.id">{{ item.title }}</el-radio>
				</div>
			</div>
			<div class="desc danger">
				{{ desc }}
			</div>

			<span slot="footer">
				<el-button @click="dialog = false">取 消</el-button>
				<el-button type="primary" @click="submit">导 出</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
export default {
	props: {
		title: {
			type: String,
			default: '导出Excel'
		},

		// 向后兼容
		stateList: {
			type: Array,
			default() {
				return []
			}
		},

		data: {
			type: Array,
			default() {
				return []
			}
		},

		reset: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			dialog: false,
			state: '1'
		}
	},

	computed: {
		list() {
			if (this.reset) {
				return [...this.stateList, ...this.data]
			}
			return [...this.getDefalutStateList(), ...this.stateList, ...this.data]
		},

		desc() {
			const item = this.list.find((item) => item.id === this.state)
			return item.desc
		}
	},

	methods: {
		getDefalutStateList() {
			return [
				{
					id: '1',
					title: '导出当前数据',
					desc: '仅导出当前页面所展示的数据, 若数据量大请耐心等待'
				},
				{
					id: '2',
					title: '条件导出所有数据',
					desc: '根据页面上所选择的条件导出所有符合条件的数据, 会请求远程资源, 若数据量大请耐心等待'
				},
				{
					id: '3',
					title: '导出所有数据',
					desc: '导出所有数据(任何数据, 不作限制), 会请求远程资源, 若数据量大请耐心等待'
				}
			]
		},

		submit() {
			const loading = this.$loading({ text: '正在导出' })
			this.$emit('submit', {
				closeLoding: loading.close.bind(loading),
				closePopup: () => {
					this.dialog = false
				},
				close: () => {
					loading.close()
					this.dialog = false
				},
				result: this.state
			})
		}
	}
}
</script>

<style scoped lang="scss"></style>
