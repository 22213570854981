import BatchImportExcel from './components/BatchImportExcel'
import ExportExcel from './components/ExportExcel'
import UmUploadImage from './components/UmUploadImage'
import UmTag from './components/UmTag'
import UmMoveWidth from './components/UmMoveWidth'
import UmFoldHeight from './components/UmFoldHeight'
import UmScaleHeight from './components/UmScaleHeight'

import Vue from 'vue'
Vue.component('BatchImportExcel', BatchImportExcel)
Vue.component('ExportExcel', ExportExcel)
Vue.component('UmUploadImage', UmUploadImage)
Vue.component('UmTag', UmTag)
Vue.component('UmMoveWidth', UmMoveWidth)
Vue.component('UmFoldHeight', UmFoldHeight)
Vue.component('UmScaleHeight', UmScaleHeight)
