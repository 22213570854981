import { isPositiveInt } from '../tools/main.es.js'

/**
 * @typedef Info
 * @property {number} count 有效数据长度
 * @property {number} data 筛选后的数据
 * @property {number} page 页码
 * @property {number} limit 需要的数据量
 * @property {number} field 模糊查询的字段
 * @property {number} keyword 模糊查询的关键字
 */

class Paging {
	constructor(data) {
		this.data = data
	}

	/**
	 * @param params 配置参数
	 * @param {number} params.page 页码
	 * @param {number} params.limit 需要的数据量
	 * @param {string} params.field 模糊查询的字段
	 * @param {string} params.keyword 模糊查询的关键字
	 */
	getData({ page, limit, field, keyword }) {
		if (!isPositiveInt(page, limit)) {
			throw new SyntaxError('page 和 limit 必须是大于 0 的正整数 !')
		}

		let start = (page - 1) * limit
		let end = start + limit
		let filterData = null

		const resultData = {
			page,
			limit,
			count: 0,
			data: []
		}

		// 如果查询条件存在
		if (field && keyword) {
			resultData.field = String(field)
			resultData.keyword = String(keyword)
			filterData = this.search({ field: resultData.field, keyword: resultData.keyword })
		} else {
			filterData = this.data
		}

		resultData.count = filterData.length

		let result = filterData.slice(start, end)
		resultData.data = result
		return resultData
	}

	/**
	 * @param params 配置参数
	 * @param {string} params.field 模糊查询的字段
	 * @param {string} params.keyword 模糊查询的关键字, 其他类型将被转为字符串
	 * @returns {{[key:string]:any}[]}
	 */
	search({ field, keyword }) {
		let filterData = []
		this.data.forEach((item) => {
			const key = String(item[field])
			key.includes(keyword) && filterData.push(item)
		})
		return filterData
	}
}

export default Paging
