// export const strToBase64 = (str) => {
// 	const encoder = new TextEncoder()
// 	const uint8Array = encoder.encode(str)
// 	const binaryString = Array.from(uint8Array)
// 		.map((byte) => String.fromCharCode(byte))
// 		.join('')
// 	return btoa(binaryString)
// }

// export const base64ToStr = (base64) => {
// 	const binaryString = atob(base64)
// 	const bytes = new Uint8Array(binaryString.length)
// 	for (let i = 0; i < binaryString.length; i++) {
// 		bytes[i] = binaryString.charCodeAt(i)
// 	}
// 	const decoder = new TextDecoder('utf-8')
// 	return decoder.decode(bytes)
// }

import { encode, decode } from 'js-base64'
export const strToBase64 = encode
export const base64ToStr = decode
