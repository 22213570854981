<template>
	<div class="um-upload-image-cantainer">
		<div class="um-container">
			<div class="um-item-container" :style="itemStyle" v-for="(item, i) in list" :key="item.id">
				<slot :list="list" :item="item" :i="i">
					<div class="um-view-container" :style="viewContainerStyle">
						<!-- <img class="um-view" :src="item.tempUrl" :style="imgStyle" /> -->
						<el-image
							class="um-view"
							:style="imgStyle"
							:src="item.tempUrl"
							:preview-src-list="list.map((el) => el.tempUrl)"
						>
						</el-image>
					</div>
				</slot>
				<div
					v-if="removeIcon"
					:style="removeIconContainerStyle"
					class="um-remove-icon-container"
					@click.stop="remove(item, i, list)"
				>
					<slot name="remove-icon" :list="list" :item="item" :i="i">
						<div :style="iconStyle" class="um-remove-icon um-icon icon-close"></div>
					</slot>
				</div>
			</div>
			<div
				v-show="list.length < limit"
				class="um-add-container"
				@click="selectFile"
				:style="addContainerStyle"
				@dragover="dragover"
				@dragleave="dragleave"
				@drop="drop"
				:class="{ 'um-drag-over': isDragover }"
			>
				<slot name="add-icon">
					<div class="um-add-icon um-icon icon-add" :style="addIconStyle"></div>
				</slot>
			</div>
		</div>
		<!-- 兼容ios -->
		<div ref="InputContainer"></div>
	</div>
</template>

<script>
import { getExt } from './file/index.js'
// import { nanoid } from 'nanoid'
// import sparkMD5 from 'spark-md5'
const sign = Symbol('[um-upload-image]')
export default {
	props: {
		development: {
			type: Boolean,
			default: false
		},

		initList: {
			type: Array,
			default() {
				return []
			}
		},

		/** 是否开启拖拽上传 */
		drag: {
			type: Boolean,
			default: false
		},

		/** 是否显示删除 icon */
		removeIcon: {
			type: Boolean,
			default: true
		},

		/** 每一项 style */
		itemStyle: {},

		/** 视图图片容器 style */
		viewContainerStyle: {},

		/** 视图图片 style */
		imgStyle: {},

		/** 删除 icon 容器 style */
		removeIconContainerStyle: {},

		/** 删除 icon style */
		iconStyle: {},

		/** 添加图片容器 style */
		addContainerStyle: {},

		/** 添加图标 style */
		addIconStyle: {},

		/** 照片方式 */
		sourceType: {
			type: Array,
			default() {
				return []
			}
		},

		/** 是否允许多选 */
		multiple: {
			type: Boolean,
			default: false
		},

		/** 钩子函数: 选择后, 是否通过选择校验 */
		checkFile: {
			type: Function
		},

		/** 钩子函数: 删除图片时 */
		beforeRemove: {
			type: Function
		},

		/** 钩子函数: 预览图片时 */
		beforePreview: {
			type: Function
		},

		limit: {
			type: Number,
			default: Infinity
		},

		hash: {
			type: Boolean,
			default: true
		},

		sizeType: {
			type: Array
		},

		/** 上传文件钩子函数 */
		upload: {
			type: Function
		}
	},

	data() {
		return {
			isDragover: false,
			list: []
		}
	},

	methods: {
		dragover(e) {
			if (!this.drag) return
			e.preventDefault()
			this.isDragover = true
		},

		dragleave() {
			this.isDragover = false
		},

		drop(e) {
			if (!this.drag) return
			e.preventDefault()
			this.isDragover = false
			const data = e.dataTransfer.files ? Array.from(e.dataTransfer.files) : []
			this.fileHandlle(data)
		},

		getList() {
			return this.list
		},

		initAdd() {
			this.list = [...this.initList]
		},

		async selectFile() {
			const input = document.createElement('input')
			input.type = 'file'
			input.accept = 'image/*'
			input.multiple = this.multiple
			if (this.sourceType.length > 0) {
				input.capture = 'environment'
			}
			input.style.display = 'none'
			input.style.visibility = 'hidden'

			// 兼容处理: 插入到页面以解决 ios 无法触发 change 事件
			this.$refs.InputContainer.appendChild(input)
			input.addEventListener('change', async (e) => {
				const files = e.target.files ? Array.from(e.target.files) : []
				input.remove() // 移除 input
				this.fileHandlle(files)
			})
			input.click()
		},

		randomStr(length = 1) {
			let str = ''
			for (let i = 0; i <= length; i++) {
				str += Math.floor(Math.random() * 36).toString(36)
			}
			return str
		},

		getId() {
			// return nanoid()
			if (this.development && process.env.NODE_ENV === 'development') {
				return 'development&' + this.randomStr(10)
			}
			return crypto.randomUUID() + '&' + this.randomStr(4)
		},

		async createFileInfo(file) {
			const id = this.getId()
			const timeStamp = Date.now()
			let ext = getExt(file.name)
			let hash = null,
				name = null

			let arrayBuffer = await file.arrayBuffer()
			if (this.hash) {
				if (this.development && process.env.NODE_ENV === 'development') {
					name = `development&${this.randomStr(10)}&${timeStamp}${ext}`
				} else {
					const hashBuf = await crypto.subtle.digest('SHA-256', arrayBuffer)
					const hashArr = Array.from(new Uint8Array(hashBuf))
					const hash = hashArr.map((byte) => byte.toString(16).padStart(2, '0')).join('')
					// const spark = new sparkMD5()
					// spark.append(arrayBuffer)
					// hash = spark.end()
					name = `${hash}&${timeStamp}${ext}`
				}
			} else {
				name = `${id}&${timeStamp}${ext}`
			}

			const self = this
			const fileInfo = {
				[sign]: true,
				id,
				timeStamp,
				ext,
				hash,
				name,
				arrayBuffer,
				tempUrl: URL.createObjectURL(file),
				isDestroy: false,
				destroy() {
					if (!fileInfo.tempUrl) return false
					const i = self.list.findIndex((item) => item.id === id)
					let item
					if (i !== -1) {
						item = self.list.splice(i, 1)[0]
					}
					URL.revokeObjectURL(fileInfo.tempUrl)
					if (!String(fileInfo.tempUrl).startsWith('blob:')) {
						fileInfo.tempUrl = null
					}
					fileInfo.isDestroy = true
					return item
				},
				file,
				// 保留, 向后兼容
				getFile() {
					return file
				},

				assign(data) {
					Object.keys(data).forEach((key) => {
						self.$set(fileInfo, key, data[key])
					})
				},

				deleteProp(key) {
					self.$delete(fileInfo, key)
				},

				addProp(key, value) {
					self.$set(fileInfo, key, value)
				},

				updateProp(key, value) {
					self.$set(fileInfo, key, value)
				}
			}
			return fileInfo
		},

		async fileHandlle(fileList) {
			const newFileList = []

			if (fileList.length + this.list.length > this.limit) {
				const len = fileList.length - Math.abs(fileList.length + this.list.length - this.limit)
				const originList = fileList
				fileList = fileList.splice(0, len)
				if (this.$listeners.limitBoundary) {
					this.$emit('limitBoundary', fileList, originList)
				}
			}

			let i = 0
			for (const file of fileList) {
				const fileInfo = await this.createFileInfo(file)
				let isAllow = true
				if (this.checkFile) {
					isAllow = await this.checkFile(fileInfo, i, fileList, newFileList)
				}

				if (isAllow) {
					newFileList.push(fileInfo)
					if (this.$listeners.adoptCheck) {
						this.$emit('adoptCheck', fileInfo, i, fileList, newFileList)
					}
				}

				i++
			}

			this.list = this.list.concat(newFileList)

			if (this.upload) {
				await this.upload(this.list, newFileList)
			}

			if (this.$listeners.add) {
				this.$emit('add', this.list, newFileList)
			}

			if (this.$listeners.change) {
				this.$emit('change', this.list)
			}
		},

		async remove(item, i, list) {
			let isAllow = true
			if (this.beforeRemove) {
				isAllow = await this.beforeRemove(item, i, list)
			}
			if (isAllow) {
				if (item[sign]) {
					item.destroy()
				} else {
					this.list.splice(i, 1)
				}
				if (this.$listeners.remove) {
					this.$emit('remove', item, i, list)
				}

				if (this.$listeners.change) {
					this.$emit('change', this.list)
				}
			}
		},

		// 暴露方法
		clear() {
			this.list.forEach((item) => {
				if (item[sign]) {
					item.destroy()
				}
			})
			this.list = []
			if (this.$listeners.change) {
				this.$emit('change', this.list)
			}
			return this.list
		},

		// 暴露方法
		quietClear() {
			this.list.forEach((item) => {
				if (item[sign]) {
					item.destroy()
				}
			})
			this.list = []
			return this.list
		},

		// 暴露方法
		async add(file) {
			this.list.push(file)
			if (this.$listeners.change) {
				this.$emit('change', this.list)
			}
			return this.list
		},

		// 暴露方法
		async quietAdd(file) {
			this.list.push(file)
			return this.list
		},

		// 暴露方法
		deleteFileById(id) {
			const i = this.list.findIndex((item) => item.id === id)

			if (i === -1) {
				throw new Error('找不到此 id ')
			}
			const item = this.list.splice(i, 1)[0]
			if (item[sign]) {
				item.destroy()
			}
			if (this.$listeners.change) {
				this.$emit('change', this.list)
			}
			return item
		},

		// 暴露方法
		quietDeleteFileById(id) {
			const i = this.list.findIndex((item) => item.id === id)

			if (i === -1) {
				throw new Error('找不到此 id ')
			}
			const item = this.list.splice(i, 1)[0]
			if (item[sign]) {
				item.destroy()
			}
			return item
		},

		// 暴露方法
		deleteFileByIndex(i) {
			const item = this.list.splice(i, 1)
			if (item[sign]) {
				item.destroy()
			}
			if (this.$listeners.change) {
				this.$emit('change', this.list)
			}
			return item
		},

		// 暴露方法
		quietDeleteFileByIndex(i) {
			const item = this.list.splice(i, 1)
			if (item[sign]) {
				item.destroy()
			}
			return item
		},

		// 暴露方法
		updateFileByIndex(i, data) {
			this.list[i] = data
			if (this.$listeners.change) {
				this.$emit('change', this.list)
			}
			return this.list
		},

		// 暴露方法
		quietUpdateFileByIndex(i) {
			this.list[i] = data
			return this.list
		}
	},

	created() {
		this.initAdd()
	},

	beforeDestroy() {
		this.list.forEach((item) => {
			if (item[sign]) {
				item.destroy()
			}
		})
	}
}
</script>

<style scoped lang="scss">
@import './styles/iconfont.scss';
.um-upload-image-cantainer {
	--um-upload-image-width: 100px;
	--um-upload-image-height: 100px;
	--um-upload-image-color: #d1d1d1;
	--um-upload-image-border: 1px dashed var(--um-upload-image-color);
	--um-upload-image-object-fit: cover;
	--um-upload-image-hover-border: 1px dashed #409eff;
	--um-upload-image-hover-color: #409eff;
	--um-upload-image-hover-tansition: color 0.2s, border-color 0.2s;
	--um-upload-image-drag-background: rgb(236, 245, 255);

	box-sizing: border-box;

	.um-container {
		position: relative;
		display: flex;
		flex-wrap: wrap;
		box-sizing: border-box;
	}
}

.um-item-container {
	position: relative;
	width: var(--um-upload-image-width);
	height: var(--um-upload-image-height);
	border: var(--um-upload-image-border);
	box-sizing: border-box;
	cursor: pointer;
	overflow: hidden;

	.um-view-container {
		width: 100%;
		height: 100%;
		box-sizing: border-box;
	}

	.um-view {
		display: block;
		width: 100%;
		height: 100%;
		object-fit: var(--um-upload-image-object-fit);
		box-sizing: border-box;
		// pointer-events: none;
	}

	.um-remove-icon-container {
		position: absolute;
		top: 0;
		right: 0;
		width: 18px;
		height: 18px;
		border-radius: 0 0 0 14px;
		box-sizing: border-box;
		background-color: rgba(0, 0, 0, 0.7);
		font-size: 12px;
		color: #fff;
		font-weight: bold;
	}

	.um-remove-icon {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 100%;
		height: 100%;
	}
}

.um-add-container {
	display: flex;
	justify-content: center;
	align-items: center;
	box-sizing: border-box;
	width: var(--um-upload-image-width);
	height: var(--um-upload-image-height);
	border: var(--um-upload-image-border);
	cursor: pointer;
	overflow: hidden;
	box-sizing: border-box;
	transition: var(--um-upload-image-hover-tansition);

	&:hover {
		border: var(--um-upload-image-hover-border);

		.um-add-icon {
			color: var(--um-upload-image-hover-color);
		}
	}
}

.um-drag-over {
	background: var(--um-upload-image-drag-background);
	border: var(--um-upload-image-hover-border);

	.um-add-icon {
		color: var(--um-upload-image-hover-color);
	}
}

.um-add-icon {
	font-size: 60px;
	color: var(--um-upload-image-color);
	transition: var(--um-upload-image-hover-tansition);
}
</style>
