import './config'
import Vue from 'vue'
import '@/shared'
import App from './App.vue'
import router from './router'
import ElementUI from 'element-ui'
import './assets/css/overallSituation.less'
import 'element-ui/lib/theme-chalk/index.css' // 默认主题
// import './assets/css/theme-green/index.css'; // 浅绿色主题
import './assets/css/icon.css'
import './components/common/directives'
import 'babel-polyfill'
import store from './store'
import '@/style/index.scss'
import Contextmenu from 'vue-contextmenujs'
Vue.use(Contextmenu)

Vue.config.productionTip = false
// Vue.config.devtools = false
Vue.use(ElementUI, {
	size: 'small'
})

new Vue({
	router,
	store,
	render: (h) => h(App)
}).$mount('#app')
