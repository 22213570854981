var Pt = typeof global == "object" && global && global.Object === Object && global, ae = typeof self == "object" && self && self.Object === Object && self, y = Pt || ae || Function("return this")(), O = y.Symbol, xt = Object.prototype, ie = xt.hasOwnProperty, se = xt.toString, A = O ? O.toStringTag : void 0;
function ce(t) {
  var e = ie.call(t, A), r = t[A];
  try {
    t[A] = void 0;
    var n = !0;
  } catch (a) {
  }
  var o = se.call(t);
  return n && (e ? t[A] = r : delete t[A]), o;
}
var ue = Object.prototype, fe = ue.toString;
function le(t) {
  return fe.call(t);
}
var pe = "[object Null]", ye = "[object Undefined]", it = O ? O.toStringTag : void 0;
function M(t) {
  return t == null ? t === void 0 ? ye : pe : it && it in Object(t) ? ce(t) : le(t);
}
function N(t) {
  return t != null && typeof t == "object";
}
var q = Array.isArray;
function F(t) {
  var e = typeof t;
  return t != null && (e == "object" || e == "function");
}
var ge = "[object AsyncFunction]", be = "[object Function]", de = "[object GeneratorFunction]", he = "[object Proxy]";
function It(t) {
  if (!F(t))
    return !1;
  var e = M(t);
  return e == be || e == de || e == ge || e == he;
}
var z = y["__core-js_shared__"], st = function() {
  var t = /[^.]+$/.exec(z && z.keys && z.keys.IE_PROTO || "");
  return t ? "Symbol(src)_1." + t : "";
}();
function Te(t) {
  return !!st && st in t;
}
var _e = Function.prototype, je = _e.toString;
function _(t) {
  if (t != null) {
    try {
      return je.call(t);
    } catch (e) {
    }
    try {
      return t + "";
    } catch (e) {
    }
  }
  return "";
}
var ve = /[\\^$.*+?()[\]{}|]/g, Oe = /^\[object .+?Constructor\]$/, we = Function.prototype, me = Object.prototype, $e = we.toString, Se = me.hasOwnProperty, Ae = RegExp(
  "^" + $e.call(Se).replace(ve, "\\$&").replace(/hasOwnProperty|(function).*?(?=\\\()| for .+?(?=\\\])/g, "$1.*?") + "$"
);
function Ee(t) {
  if (!F(t) || Te(t))
    return !1;
  var e = It(t) ? Ae : Oe;
  return e.test(_(t));
}
function Pe(t, e) {
  return t == null ? void 0 : t[e];
}
function j(t, e) {
  var r = Pe(t, e);
  return Ee(r) ? r : void 0;
}
var K = j(y, "WeakMap"), ct = Object.create, xe = /* @__PURE__ */ function() {
  function t() {
  }
  return function(e) {
    if (!F(e))
      return {};
    if (ct)
      return ct(e);
    t.prototype = e;
    var r = new t();
    return t.prototype = void 0, r;
  };
}();
function Ie(t, e) {
  var r = -1, n = t.length;
  for (e || (e = Array(n)); ++r < n; )
    e[r] = t[r];
  return e;
}
var ut = function() {
  try {
    var t = j(Object, "defineProperty");
    return t({}, "", {}), t;
  } catch (e) {
  }
}();
function Ce(t, e) {
  for (var r = -1, n = t == null ? 0 : t.length; ++r < n && e(t[r], r, t) !== !1; )
    ;
  return t;
}
var De = 9007199254740991, Me = /^(?:0|[1-9]\d*)$/;
function Ne(t, e) {
  var r = typeof t;
  return e = e == null ? De : e, !!e && (r == "number" || r != "symbol" && Me.test(t)) && t > -1 && t % 1 == 0 && t < e;
}
function Ct(t, e, r) {
  e == "__proto__" && ut ? ut(t, e, {
    configurable: !0,
    enumerable: !0,
    value: r,
    writable: !0
  }) : t[e] = r;
}
function Dt(t, e) {
  return t === e || t !== t && e !== e;
}
var Fe = Object.prototype, Re = Fe.hasOwnProperty;
function Mt(t, e, r) {
  var n = t[e];
  (!(Re.call(t, e) && Dt(n, r)) || r === void 0 && !(e in t)) && Ct(t, e, r);
}
function L(t, e, r, n) {
  var o = !r;
  r || (r = {});
  for (var a = -1, i = e.length; ++a < i; ) {
    var u = e[a], f = n ? n(r[u], t[u], u, r, t) : void 0;
    f === void 0 && (f = t[u]), o ? Ct(r, u, f) : Mt(r, u, f);
  }
  return r;
}
var Le = 9007199254740991;
function Nt(t) {
  return typeof t == "number" && t > -1 && t % 1 == 0 && t <= Le;
}
function Ft(t) {
  return t != null && Nt(t.length) && !It(t);
}
var Be = Object.prototype;
function X(t) {
  var e = t && t.constructor, r = typeof e == "function" && e.prototype || Be;
  return t === r;
}
function Ue(t, e) {
  for (var r = -1, n = Array(t); ++r < t; )
    n[r] = e(r);
  return n;
}
var Ge = "[object Arguments]";
function ft(t) {
  return N(t) && M(t) == Ge;
}
var Rt = Object.prototype, ze = Rt.hasOwnProperty, Ye = Rt.propertyIsEnumerable, Ke = ft(/* @__PURE__ */ function() {
  return arguments;
}()) ? ft : function(t) {
  return N(t) && ze.call(t, "callee") && !Ye.call(t, "callee");
};
function He() {
  return !1;
}
var Lt = typeof exports == "object" && exports && !exports.nodeType && exports, lt = Lt && typeof module == "object" && module && !module.nodeType && module, Ve = lt && lt.exports === Lt, pt = Ve ? y.Buffer : void 0, We = pt ? pt.isBuffer : void 0, Bt = We || He, qe = "[object Arguments]", Xe = "[object Array]", Je = "[object Boolean]", Ze = "[object Date]", Qe = "[object Error]", ke = "[object Function]", tr = "[object Map]", er = "[object Number]", rr = "[object Object]", nr = "[object RegExp]", or = "[object Set]", ar = "[object String]", ir = "[object WeakMap]", sr = "[object ArrayBuffer]", cr = "[object DataView]", ur = "[object Float32Array]", fr = "[object Float64Array]", lr = "[object Int8Array]", pr = "[object Int16Array]", yr = "[object Int32Array]", gr = "[object Uint8Array]", br = "[object Uint8ClampedArray]", dr = "[object Uint16Array]", hr = "[object Uint32Array]", c = {};
c[ur] = c[fr] = c[lr] = c[pr] = c[yr] = c[gr] = c[br] = c[dr] = c[hr] = !0;
c[qe] = c[Xe] = c[sr] = c[Je] = c[cr] = c[Ze] = c[Qe] = c[ke] = c[tr] = c[er] = c[rr] = c[nr] = c[or] = c[ar] = c[ir] = !1;
function Tr(t) {
  return N(t) && Nt(t.length) && !!c[M(t)];
}
function J(t) {
  return function(e) {
    return t(e);
  };
}
var Ut = typeof exports == "object" && exports && !exports.nodeType && exports, P = Ut && typeof module == "object" && module && !module.nodeType && module, _r = P && P.exports === Ut, Y = _r && Pt.process, w = function() {
  try {
    var t = P && P.require && P.require("util").types;
    return t || Y && Y.binding && Y.binding("util");
  } catch (e) {
  }
}(), yt = w && w.isTypedArray, jr = yt ? J(yt) : Tr, vr = Object.prototype, Or = vr.hasOwnProperty;
function Gt(t, e) {
  var r = q(t), n = !r && Ke(t), o = !r && !n && Bt(t), a = !r && !n && !o && jr(t), i = r || n || o || a, u = i ? Ue(t.length, String) : [], f = u.length;
  for (var l in t)
    (e || Or.call(t, l)) && !(i && // Safari 9 has enumerable `arguments.length` in strict mode.
    (l == "length" || // Node.js 0.10 has enumerable non-index properties on buffers.
    o && (l == "offset" || l == "parent") || // PhantomJS 2 has enumerable non-index properties on typed arrays.
    a && (l == "buffer" || l == "byteLength" || l == "byteOffset") || // Skip index properties.
    Ne(l, f))) && u.push(l);
  return u;
}
function zt(t, e) {
  return function(r) {
    return t(e(r));
  };
}
var wr = zt(Object.keys, Object), mr = Object.prototype, $r = mr.hasOwnProperty;
function Sr(t) {
  if (!X(t))
    return wr(t);
  var e = [];
  for (var r in Object(t))
    $r.call(t, r) && r != "constructor" && e.push(r);
  return e;
}
function Z(t) {
  return Ft(t) ? Gt(t) : Sr(t);
}
function Ar(t) {
  var e = [];
  if (t != null)
    for (var r in Object(t))
      e.push(r);
  return e;
}
var Er = Object.prototype, Pr = Er.hasOwnProperty;
function xr(t) {
  if (!F(t))
    return Ar(t);
  var e = X(t), r = [];
  for (var n in t)
    n == "constructor" && (e || !Pr.call(t, n)) || r.push(n);
  return r;
}
function Q(t) {
  return Ft(t) ? Gt(t, !0) : xr(t);
}
var I = j(Object, "create");
function Ir() {
  this.__data__ = I ? I(null) : {}, this.size = 0;
}
function Cr(t) {
  var e = this.has(t) && delete this.__data__[t];
  return this.size -= e ? 1 : 0, e;
}
var Dr = "__lodash_hash_undefined__", Mr = Object.prototype, Nr = Mr.hasOwnProperty;
function Fr(t) {
  var e = this.__data__;
  if (I) {
    var r = e[t];
    return r === Dr ? void 0 : r;
  }
  return Nr.call(e, t) ? e[t] : void 0;
}
var Rr = Object.prototype, Lr = Rr.hasOwnProperty;
function Br(t) {
  var e = this.__data__;
  return I ? e[t] !== void 0 : Lr.call(e, t);
}
var Ur = "__lodash_hash_undefined__";
function Gr(t, e) {
  var r = this.__data__;
  return this.size += this.has(t) ? 0 : 1, r[t] = I && e === void 0 ? Ur : e, this;
}
function T(t) {
  var e = -1, r = t == null ? 0 : t.length;
  for (this.clear(); ++e < r; ) {
    var n = t[e];
    this.set(n[0], n[1]);
  }
}
T.prototype.clear = Ir;
T.prototype.delete = Cr;
T.prototype.get = Fr;
T.prototype.has = Br;
T.prototype.set = Gr;
function zr() {
  this.__data__ = [], this.size = 0;
}
function B(t, e) {
  for (var r = t.length; r--; )
    if (Dt(t[r][0], e))
      return r;
  return -1;
}
var Yr = Array.prototype, Kr = Yr.splice;
function Hr(t) {
  var e = this.__data__, r = B(e, t);
  if (r < 0)
    return !1;
  var n = e.length - 1;
  return r == n ? e.pop() : Kr.call(e, r, 1), --this.size, !0;
}
function Vr(t) {
  var e = this.__data__, r = B(e, t);
  return r < 0 ? void 0 : e[r][1];
}
function Wr(t) {
  return B(this.__data__, t) > -1;
}
function qr(t, e) {
  var r = this.__data__, n = B(r, t);
  return n < 0 ? (++this.size, r.push([t, e])) : r[n][1] = e, this;
}
function g(t) {
  var e = -1, r = t == null ? 0 : t.length;
  for (this.clear(); ++e < r; ) {
    var n = t[e];
    this.set(n[0], n[1]);
  }
}
g.prototype.clear = zr;
g.prototype.delete = Hr;
g.prototype.get = Vr;
g.prototype.has = Wr;
g.prototype.set = qr;
var C = j(y, "Map");
function Xr() {
  this.size = 0, this.__data__ = {
    hash: new T(),
    map: new (C || g)(),
    string: new T()
  };
}
function Jr(t) {
  var e = typeof t;
  return e == "string" || e == "number" || e == "symbol" || e == "boolean" ? t !== "__proto__" : t === null;
}
function U(t, e) {
  var r = t.__data__;
  return Jr(e) ? r[typeof e == "string" ? "string" : "hash"] : r.map;
}
function Zr(t) {
  var e = U(this, t).delete(t);
  return this.size -= e ? 1 : 0, e;
}
function Qr(t) {
  return U(this, t).get(t);
}
function kr(t) {
  return U(this, t).has(t);
}
function tn(t, e) {
  var r = U(this, t), n = r.size;
  return r.set(t, e), this.size += r.size == n ? 0 : 1, this;
}
function m(t) {
  var e = -1, r = t == null ? 0 : t.length;
  for (this.clear(); ++e < r; ) {
    var n = t[e];
    this.set(n[0], n[1]);
  }
}
m.prototype.clear = Xr;
m.prototype.delete = Zr;
m.prototype.get = Qr;
m.prototype.has = kr;
m.prototype.set = tn;
function Yt(t, e) {
  for (var r = -1, n = e.length, o = t.length; ++r < n; )
    t[o + r] = e[r];
  return t;
}
var en = zt(Object.getPrototypeOf, Object);
const Kt = en;
function rn() {
  this.__data__ = new g(), this.size = 0;
}
function nn(t) {
  var e = this.__data__, r = e.delete(t);
  return this.size = e.size, r;
}
function on(t) {
  return this.__data__.get(t);
}
function an(t) {
  return this.__data__.has(t);
}
var sn = 200;
function cn(t, e) {
  var r = this.__data__;
  if (r instanceof g) {
    var n = r.__data__;
    if (!C || n.length < sn - 1)
      return n.push([t, e]), this.size = ++r.size, this;
    r = this.__data__ = new m(n);
  }
  return r.set(t, e), this.size = r.size, this;
}
function $(t) {
  var e = this.__data__ = new g(t);
  this.size = e.size;
}
$.prototype.clear = rn;
$.prototype.delete = nn;
$.prototype.get = on;
$.prototype.has = an;
$.prototype.set = cn;
function un(t, e) {
  return t && L(e, Z(e), t);
}
function fn(t, e) {
  return t && L(e, Q(e), t);
}
var Ht = typeof exports == "object" && exports && !exports.nodeType && exports, gt = Ht && typeof module == "object" && module && !module.nodeType && module, ln = gt && gt.exports === Ht, bt = ln ? y.Buffer : void 0, dt = bt ? bt.allocUnsafe : void 0;
function pn(t, e) {
  if (e)
    return t.slice();
  var r = t.length, n = dt ? dt(r) : new t.constructor(r);
  return t.copy(n), n;
}
function yn(t, e) {
  for (var r = -1, n = t == null ? 0 : t.length, o = 0, a = []; ++r < n; ) {
    var i = t[r];
    e(i, r, t) && (a[o++] = i);
  }
  return a;
}
function Vt() {
  return [];
}
var gn = Object.prototype, bn = gn.propertyIsEnumerable, ht = Object.getOwnPropertySymbols, k = ht ? function(t) {
  return t == null ? [] : (t = Object(t), yn(ht(t), function(e) {
    return bn.call(t, e);
  }));
} : Vt;
function dn(t, e) {
  return L(t, k(t), e);
}
var hn = Object.getOwnPropertySymbols, Wt = hn ? function(t) {
  for (var e = []; t; )
    Yt(e, k(t)), t = Kt(t);
  return e;
} : Vt;
function Tn(t, e) {
  return L(t, Wt(t), e);
}
function qt(t, e, r) {
  var n = e(t);
  return q(t) ? n : Yt(n, r(t));
}
function _n(t) {
  return qt(t, Z, k);
}
function jn(t) {
  return qt(t, Q, Wt);
}
var H = j(y, "DataView"), V = j(y, "Promise"), W = j(y, "Set"), Tt = "[object Map]", vn = "[object Object]", _t = "[object Promise]", jt = "[object Set]", vt = "[object WeakMap]", Ot = "[object DataView]", On = _(H), wn = _(C), mn = _(V), $n = _(W), Sn = _(K), h = M;
(H && h(new H(new ArrayBuffer(1))) != Ot || C && h(new C()) != Tt || V && h(V.resolve()) != _t || W && h(new W()) != jt || K && h(new K()) != vt) && (h = function(t) {
  var e = M(t), r = e == vn ? t.constructor : void 0, n = r ? _(r) : "";
  if (n)
    switch (n) {
      case On:
        return Ot;
      case wn:
        return Tt;
      case mn:
        return _t;
      case $n:
        return jt;
      case Sn:
        return vt;
    }
  return e;
});
const tt = h;
var An = Object.prototype, En = An.hasOwnProperty;
function Pn(t) {
  var e = t.length, r = new t.constructor(e);
  return e && typeof t[0] == "string" && En.call(t, "index") && (r.index = t.index, r.input = t.input), r;
}
var wt = y.Uint8Array;
function et(t) {
  var e = new t.constructor(t.byteLength);
  return new wt(e).set(new wt(t)), e;
}
function xn(t, e) {
  var r = e ? et(t.buffer) : t.buffer;
  return new t.constructor(r, t.byteOffset, t.byteLength);
}
var In = /\w*$/;
function Cn(t) {
  var e = new t.constructor(t.source, In.exec(t));
  return e.lastIndex = t.lastIndex, e;
}
var mt = O ? O.prototype : void 0, $t = mt ? mt.valueOf : void 0;
function Dn(t) {
  return $t ? Object($t.call(t)) : {};
}
function Mn(t, e) {
  var r = e ? et(t.buffer) : t.buffer;
  return new t.constructor(r, t.byteOffset, t.length);
}
var Nn = "[object Boolean]", Fn = "[object Date]", Rn = "[object Map]", Ln = "[object Number]", Bn = "[object RegExp]", Un = "[object Set]", Gn = "[object String]", zn = "[object Symbol]", Yn = "[object ArrayBuffer]", Kn = "[object DataView]", Hn = "[object Float32Array]", Vn = "[object Float64Array]", Wn = "[object Int8Array]", qn = "[object Int16Array]", Xn = "[object Int32Array]", Jn = "[object Uint8Array]", Zn = "[object Uint8ClampedArray]", Qn = "[object Uint16Array]", kn = "[object Uint32Array]";
function to(t, e, r) {
  var n = t.constructor;
  switch (e) {
    case Yn:
      return et(t);
    case Nn:
    case Fn:
      return new n(+t);
    case Kn:
      return xn(t, r);
    case Hn:
    case Vn:
    case Wn:
    case qn:
    case Xn:
    case Jn:
    case Zn:
    case Qn:
    case kn:
      return Mn(t, r);
    case Rn:
      return new n();
    case Ln:
    case Gn:
      return new n(t);
    case Bn:
      return Cn(t);
    case Un:
      return new n();
    case zn:
      return Dn(t);
  }
}
function eo(t) {
  return typeof t.constructor == "function" && !X(t) ? xe(Kt(t)) : {};
}
var ro = "[object Map]";
function no(t) {
  return N(t) && tt(t) == ro;
}
var St = w && w.isMap, oo = St ? J(St) : no, ao = "[object Set]";
function io(t) {
  return N(t) && tt(t) == ao;
}
var At = w && w.isSet, so = At ? J(At) : io, co = 1, uo = 2, fo = 4, Xt = "[object Arguments]", lo = "[object Array]", po = "[object Boolean]", yo = "[object Date]", go = "[object Error]", Jt = "[object Function]", bo = "[object GeneratorFunction]", ho = "[object Map]", To = "[object Number]", Zt = "[object Object]", _o = "[object RegExp]", jo = "[object Set]", vo = "[object String]", Oo = "[object Symbol]", wo = "[object WeakMap]", mo = "[object ArrayBuffer]", $o = "[object DataView]", So = "[object Float32Array]", Ao = "[object Float64Array]", Eo = "[object Int8Array]", Po = "[object Int16Array]", xo = "[object Int32Array]", Io = "[object Uint8Array]", Co = "[object Uint8ClampedArray]", Do = "[object Uint16Array]", Mo = "[object Uint32Array]", s = {};
s[Xt] = s[lo] = s[mo] = s[$o] = s[po] = s[yo] = s[So] = s[Ao] = s[Eo] = s[Po] = s[xo] = s[ho] = s[To] = s[Zt] = s[_o] = s[jo] = s[vo] = s[Oo] = s[Io] = s[Co] = s[Do] = s[Mo] = !0;
s[go] = s[Jt] = s[wo] = !1;
function x(t, e, r, n, o, a) {
  var i, u = e & co, f = e & uo, l = e & fo;
  if (r && (i = o ? r(t, n, o, a) : r(t)), i !== void 0)
    return i;
  if (!F(t))
    return t;
  var S = q(t);
  if (S) {
    if (i = Pn(t), !u)
      return Ie(t, i);
  } else {
    var v = tt(t), nt = v == Jt || v == bo;
    if (Bt(t))
      return pn(t, u);
    if (v == Zt || v == Xt || nt && !o) {
      if (i = f || nt ? {} : eo(t), !u)
        return f ? Tn(t, fn(i, t)) : dn(t, un(i, t));
    } else {
      if (!s[v])
        return o ? t : {};
      i = to(t, v, u);
    }
  }
  a || (a = new $());
  var ot = a.get(t);
  if (ot)
    return ot;
  a.set(t, i), so(t) ? t.forEach(function(b) {
    i.add(x(b, e, r, b, t, a));
  }) : oo(t) && t.forEach(function(b, d) {
    i.set(d, x(b, e, r, d, t, a));
  });
  var oe = l ? f ? jn : _n : f ? Q : Z, at = S ? void 0 : oe(t);
  return Ce(at || t, function(b, d) {
    at && (d = b, b = t[d]), Mt(i, d, x(b, e, r, d, t, a));
  }), i;
}
var No = 1, Fo = 4;
function Qt(t) {
  return x(t, No | Fo);
}
var Ro = 1, Lo = 4;
function Bo(t, e) {
  return e = typeof e == "function" ? e : void 0, x(t, Ro | Lo, e);
}
const rt = (...t) => t.length === 0 ? !1 : t.every((e) => !(e === "" || e === null || e === void 0 || e === 1 / 0 || e === -1 / 0 || Number.isNaN(e))), p = (t) => t === null ? "null" : Array.isArray(t) ? "array" : typeof t, Uo = (t, e) => {
  const r = (n) => {
    if (p(n) === "array")
      return n.filter((o) => {
        const a = p(o);
        return (a === "array" || a === "object") && (o = r(o)), t(o);
      });
    if (p(n) === "object") {
      for (const o in n)
        t(n[o]) || delete n[o];
      if (e === "array")
        return n;
    } else
      return n;
  };
  return r;
}, Vo = (t, e = rt) => {
  const r = p(t);
  return Bo(t, Uo(e, r));
}, D = (...t) => t.length === 0 ? !1 : t.every((e) => !!Number.isFinite(e)), Wo = (t, e = 200) => {
  if (typeof t != "function")
    throw new TypeError('"callback" type must be a function !');
  if (!D(e))
    throw new TypeError('"delay" type must be a number and delay value not "NaN" or "Infinity" or "-Infinity" !');
  let r;
  return function(...n) {
    clearTimeout(r), r = setTimeout(() => {
      t.apply(this, n);
    }, e);
  };
}, qo = (t, e = "YYYY-MM-DD hh:mm:ss", r = !1) => {
  let n;
  if (t instanceof Date ? n = t : n = new Date(t), Number.isNaN(n.getTime()))
    throw new TypeError('"time" cannot be converted to a Date object');
  const o = String(n.getFullYear()).padStart(4, "0"), a = String(n.getMonth() + 1).padStart(2, "0"), i = String(n.getDate()).padStart(2, "0"), u = String(n.getHours()).padStart(2, "0"), f = String(n.getMinutes()).padStart(2, "0"), l = String(n.getSeconds()).padStart(2, "0");
  let S = "";
  return r && (S = n.getMilliseconds()), e.replaceAll("YYYY", o).replaceAll("MM", a).replaceAll("DD", i).replaceAll("hh", u).replaceAll("mm", f).replaceAll("ss", l) + S;
}, Xo = (t, e, r) => {
  if (!D(e, r))
    throw new TypeError('"min" and "max" must be a effective number');
  if (Number.isNaN(t) || t !== 1 / 0 && t !== -1 / 0 && !D(t))
    return !1;
  const n = String(t).split(".")[1] || "";
  return n.length >= e && n.length <= r;
}, Jo = (...t) => t.length === 0 ? !1 : t.every((e) => !!(Number.isInteger(e) && e > 0)), Zo = (...t) => t.length === 0 ? !1 : t.every((e) => Number.isInteger(e)), Qo = (...t) => t.length === 0 ? !1 : t.every((e) => typeof e == "number"), ko = (...t) => t.length === 0 ? !1 : t.every((e) => typeof e == "string"), Go = (t, e, r = []) => {
  const n = p(t);
  if (!(n === "function" || n === "object" || n === "array"))
    throw new TypeError('"data" must be a `object` or `array` or `function`');
  if (typeof e != "function")
    throw new TypeError('"testFunc" must be a `function`');
  if (p(r) !== "array")
    throw new TypeError('"notTestField" must be a `array`');
  const o = Object.keys(t);
  for (let a of o)
    if (!r.includes(a) && !e(t[a], a, t))
      return !1;
  return !0;
}, ta = (t, e) => Go(t, (r) => rt(r), e), ea = (t, e = !0) => {
  const r = typeof t;
  if (!(r === "number" || r === "string"))
    return !1;
  if (r === "number")
    return D(t);
  if (t.trim() === "")
    return !1;
  if (e) {
    if (/\s/g.test(t))
      return !1;
    if (t.includes(".")) {
      if (t.substring(0, 2) === "00" || !/[0-9]\.[0-9]/.test(t))
        return !1;
    } else if (t.length > 1 && t[0] === "0")
      return !1;
  }
  return D(Number(t));
}, zo = (t, e = []) => {
  for (let r in t) {
    if (!Object.prototype.hasOwnProperty.call(t, r) || e.includes(r))
      continue;
    const n = t[r], o = p(n);
    if ((o === "object" || o === "array") && !zo(n, e) || !rt(n))
      return !1;
  }
  return !0;
}, kt = Symbol("只读代理标识"), R = Symbol("代理数据:只读"), te = Symbol("解除只读代理包装"), Yo = Symbol("克隆数据只读代理数据"), E = (t, e = "default") => {
  let r = {};
  const n = p(e);
  if (n === "string")
    Et(e), r = {
      mode: e,
      unReadOnly: !1,
      repeatReadOnly: !1
    };
  else if (n === "object") {
    let o = e.mode;
    o === void 0 && (o = "default"), Et(o), r.mode = o, r.unReadOnly = !!e.unReadOnly, r.repeatReadOnly = !!e.repeatReadOnly, r.sign = e.sign;
  } else
    throw new TypeError('"options" must be a string or object');
  return G(t) && !r.repeatReadOnly ? t : new Proxy(t, {
    get(o, a) {
      if (a === R)
        return R;
      if (a === kt)
        return r.sign;
      if (a === Yo)
        return o;
      if (a === te) {
        if (r.unReadOnly)
          return o;
        throw new Error('The current data "options.unReadOnly" is false !');
      }
      const i = p(o[a]);
      return i === "function" ? r.mode === "default" ? o[a] === Array.prototype[Symbol.iterator] ? E(o[a], e) : E(o[a].bind(Ko), e) : E(o[a].bind(o), e) : i === "object" || i === "array" ? E(o[a], e) : o[a];
    },
    set(o) {
      return console.warn(o, "The current data is read-only !"), !0;
    },
    deleteProperty(o) {
      return console.warn(o, "The current data is read-only !"), !0;
    },
    apply(o, a, i) {
      return r.mode === "strict" ? (console.warn(
        o,
        'The current data configuration "options.mode" is "strict" , "strict" mode prohibit calling functions !'
      ), !1) : typeof r.mode == "function" && !r.mode(o, a, i) ? !1 : o.apply(a, i);
    }
  });
}, Et = (t) => {
  const e = p(t), r = new TypeError('"options.mode" must be a "strict" | "default" | "looseFitting" | function !');
  if (e === "string") {
    if (!["strict", "default", "looseFitting"].includes(t))
      throw r;
    return;
  }
  if (e !== "function")
    throw r;
}, Ko = new Proxy(
  {},
  {
    set(t, e) {
      return console.warn(
        e,
        'The current data configuration "options.mode" is "default" , cannot be changed data through "this" !'
      ), !0;
    }
  }
), ee = ["object", "array", "function"], G = (t) => {
  const e = p(t);
  return !(!ee.includes(e) || t[R] !== R);
}, re = (t, e) => G(t) === !1 ? !1 : Object.is(t[kt], e), Ho = (t) => Qt(t), ne = (t, e) => {
  const r = p(t);
  if (!ee.includes(r))
    return t;
  if (!re(t, e))
    throw console.warn(t, 'The current data "sign" is inconsistent !'), new TypeError('The current data "sign" is inconsistent !');
  const n = t[te];
  return G(n) ? ne(n, e) : n;
}, ra = /* @__PURE__ */ Object.freeze(/* @__PURE__ */ Object.defineProperty({
  __proto__: null,
  checkReadOnlySign: re,
  cloneReadOnlyData: Ho,
  isReadOnly: G,
  readOnly: E,
  unReadOnly: ne
}, Symbol.toStringTag, { value: "Module" })), na = (t, ...e) => (e.forEach((r) => {
  Object.keys(r).forEach((n) => {
    if (!Object.hasOwn(t, n)) {
      t[n] = r[n];
      return;
    }
    t[n] === void 0 && (t[n] = r[n]);
  });
}), t), oa = (t, ...e) => (e.forEach((r) => {
  Reflect.ownKeys(r).forEach((n) => {
    if (!Object.hasOwn(t, n)) {
      t[n] = r[n];
      return;
    }
    t[n] === void 0 && (t[n] = r[n]);
  });
}), t), aa = (t, e, r, n = !1) => {
  const o = {};
  return e.forEach((a) => {
    if (r) {
      (a in t || n) && (o[a] = t[a]);
      return;
    }
    if (Object.hasOwn(t, a)) {
      o[a] = t[a];
      return;
    }
    n && (o[a] = void 0);
  }), o;
}, ia = (t, e, r) => {
  const n = r ? t : /* @__PURE__ */ Object.create(null);
  return Reflect.ownKeys(e).forEach((o) => {
    const a = Object.hasOwn(t, o) ? t[o] : void 0;
    n[o] = e[o](a, o, t);
  }), n;
}, sa = (t, e, r) => {
  const n = [];
  for (let o = 0; o < e; o++)
    n.push(Qt(t));
  return r !== void 0 ? n.join(r) : n;
};
export {
  ra as ReadOnly,
  re as checkReadOnlySign,
  Qt as cloneDeep,
  Bo as cloneDeepWith,
  Vo as cloneEffectiveValue,
  Ho as cloneReadOnlyData,
  Wo as debounce,
  qo as formatDate,
  aa as getProperty,
  Xo as isDecimalLen,
  rt as isEffectiveValue,
  Jo as isId,
  Zo as isInt,
  D as isNum,
  Qo as isNumber,
  Jo as isPositiveInt,
  G as isReadOnly,
  ea as isStrNum,
  ko as isString,
  Go as isTest,
  p as isType,
  ia as mapData,
  ta as notEmpty,
  zo as notEmptyDeep,
  E as readOnly,
  sa as repeatData,
  na as setDefaultData,
  oa as setDefaultDataAll,
  ne as unReadOnly
};
