<template>
	<div class="container-layout">
		<div class="header flex-space-between">
			<div class="flex">
				<slot
					name="btn-start"
					:data="tableData"
					:dataAll="data"
					:updateDataByIndex="updateDataByIndex"
					:updateDataByExcelIndex="updateDataByExcelIndex"
					:errorList="errorList"
				></slot>
				<span class="mb10 mr10" v-if="showDownloadTemplate">
					<el-button type="warning" @click="downloadModel">模板下载</el-button>
				</span>
				<span class="mb10 mr10">
					<el-button type="success" icon="el-icon-folder-add" @click="openSelectFile">
						批量上传 - {{ data.length }}
					</el-button>
				</span>
				<span class="mb10 mr10" v-if="showSubmit">
					<el-button
						type="primary"
						:disabled="data.length === 0 || errorList.length > 0 || !isSubmit"
						@click="submit(data)"
					>
						提交上传
					</el-button>
				</span>
				<span class="mb10 mr10" v-if="showExportError">
					<el-button type="warning" :disabled="errorList.length === 0" @click="exportError(errorList)">
						导出错误表 - {{ errorList.length }}
					</el-button>
				</span>
				<slot
					name="btn-end"
					:data="tableData"
					:dataAll="data"
					:updateDataByIndex="updateDataByIndex"
					:updateDataByExcelIndex="updateDataByExcelIndex"
					:errorList="errorList"
				></slot>
			</div>
			<div v-if="showSearch">
				<form @submit.prevent @keydown.enter="searchFn">
					<el-input :placeholder="searchDesc" v-model="search.data.keyword" clearable class="mb10 w240">
					</el-input>
					<span class="mb10">
						<el-button @click="searchFn">搜索</el-button>
					</span>
				</form>
			</div>
		</div>

		<div class="mt10">
			<slot
				name="table"
				:data="tableData"
				:dataAll="data"
				:updateDataByIndex="updateDataByIndex"
				:updateDataByExcelIndex="updateDataByExcelIndex"
				:errorList="errorList"
			></slot>
			<div class="paging flex-end mt10">
				<el-pagination
					:current-page="paging.page"
					:page-sizes="paging.size"
					:page-size="paging.limit"
					layout="total, sizes, prev, pager, next, jumper"
					:total="paging.count"
					background
					@size-change="pagingSizeChange"
					@current-change="pagingCurrentChange"
				>
				</el-pagination>
			</div>
		</div>

		<a :href="modelURL" download ref="AElement" class="hidden"></a>
		<input type="file" class="hidden" ref="FileInput" @change="fileHandle" accept=".xlsx" />
	</div>
</template>

<script>
import { createExcel, createLoad, CreateLoadType, Paging } from '@/utils'
import { MessageBox, Loading } from 'element-ui'
import { cloneDeep } from 'lodash-es'
export default {
	props: {
		/** 是否显示下载模板按钮 */
		showDownloadTemplate: {
			type: Boolean,
			default: true
		},

		/** 是否显示提交上传按钮 */
		showSubmit: {
			type: Boolean,
			default: true
		},

		/** 是否显示导出错误模块 */
		showExportError: {
			type: Boolean,
			default: true
		},

		/** 是否显示搜索模块 */
		showSearch: {
			type: Boolean,
			default: true
		},

		/** 模版下载地址 */
		modelURL: {
			type: [String, Function],
			default: ''
		},

		/** 截取掉的行数 */
		sliceLength: {
			type: Number,
			default: 0
		},

		/** 模糊搜索的字段 */
		searchField: {
			type: [String, Array],
			default: '*'
		},

		/** 模糊搜索文本框描述 */
		searchDesc: {
			type: String,
			default: '请输入关键字'
		},

		/** 解析 excel 的配置 */
		config: {
			type: Object,
			default() {
				return {}
			}
		},

		/** 错误导出配置 */
		exportErrorConfig: {
			type: Object,
			default() {
				return {}
			}
		},

		/** 预解析函数 */
		preParse: {
			type: Function
		}
	},

	data() {
		return {
			// loading 最小间隔时间
			loadingMinDifference: 500,
			// 解析后分页前的数据
			data: [],
			paging: {
				/** @type {Paging} */
				utils: null
			},
			search: {
				data: {
					keyword: ''
				}
			},
			errorList: [],
			// 分页后的数据
			tableData: [],
			// 是否可以提交
			isSubmit: false
		}
	},

	methods: {
		updateDataByIndex(i, key, value) {
			const item = this.data[i]
			if (!item) {
				throw new Error(`未找到索引为 ${excelIndex} 的数据`)
			}
			this.$set(item, key, value)
		},

		updateDataByExcelIndex(excelIndex, key, value) {
			const item = this.data.find((item) => item.$excelIndex === excelIndex)
			if (!item) {
				throw new Error(`未找到索引为 ${excelIndex} 的数据`)
			}
			this.$set(item, key, value)
		},

		/** 数据初始化 */
		init() {
			this.data = []
			this.paging = {
				page: 1,
				limit: 20,
				count: 0,
				size: [
					1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 15, 20, 25, 30, 35, 40, 50, 100, 200, 300, 400, 500, 600, 700, 800,
					900, 1000, 2000, 3000
				],
				utils: null
			}
			this.search = {
				data: {
					keyword: ''
				}
			}
			this.errorList = []
			this.tableData = []
			this.isSubmit = false
		},
		/** 下载模板 */
		downloadModel() {
			if (typeof this.modelURL === 'string') {
				this.$refs.AElement.click()
			} else {
				this.modelURL()
			}
		},

		/** 打开文件选择器 */
		openSelectFile() {
			const fileInput = this.$refs.FileInput
			fileInput.value = null
			fileInput.click()
		},

		/** 处理文件 */
		async fileHandle(e) {
			const filesList = e.target.files || []
			if (filesList.length === 0) return
			const file = filesList[0]
			this.init()
			this.$emit('beforeFileHandle')
			const startTimer = Date.now()
			const loading = Loading.service({
				lock: true,
				text: '正在解析数据',
				spinner: 'el-icon-loading'
			})
			/** @type {CreateLoadType.CreateConfig} */
			const newConfig = {
				...this.config,
				fields:
					typeof this.config.fields === 'undefined'
						? []
						: this.config.fields.map((conf) => {
								if (typeof conf === 'object' && conf !== null && typeof conf.cell === 'function') {
									return {
										...conf,
										cell: (ctx) => {
											if (ctx.rowInfo.index < this.sliceLength) {
												return ctx.cell
											}
											return conf.cell({
												...ctx,
												setData: (key, value) => {
													this.$set(ctx.rowInfo.data, key, value)
													return ctx.rowInfo.data
												}
											})
										}
									}
								}
								return conf
						  })
			}

			try {
				const load = createLoad([newConfig])
				const excel = await load.browser.parseXLSX(file)
				const mapData = excel.data[0] || []
				const data = mapData.slice(this.sliceLength)
				data.forEach((it, i) => {
					it.$excelIndex = i + 1 + this.sliceLength // Excel 行
					if (it.$state === void 0) {
						it.$state = 0
					}
					if (it.$msg === void 0) {
						it.$msg = '待上传'
					}
					if (it.$state < 0) {
						this.errorList.push(it)
					}
				})

				if (this.preParse) {
					this.data = await this.preParse(data)
				} else {
					this.data = data
				}

				this.paging.utils = new Paging(this.data)
				this.getTableData()
				this.isSubmit = true
			} catch (error) {
				console.error(error)
				MessageBox.confirm('数据解析失败 !', '消息提示', {
					showCancelButton: false,
					confirmButtonText: ' 确定',
					type: 'error'
				}).catch((err) => {
					console.error(err)
				})
			}

			this.$emit('afterFileHandle', this.data)
			const endTimer = Date.now()
			console.log(`解析耗时: ${(endTimer - startTimer) / 1000}秒`)
			if (endTimer - startTimer >= this.loadingMinDifference) {
				loading.close()
			} else {
				setTimeout(() => {
					loading.close()
				}, this.loadingMinDifference - (endTimer - startTimer))
			}
		},

		searchFn() {
			this.paging.page = 1
			this.getTableData()
		},

		/** 获取页面数据 */
		getTableData() {
			if (!this.paging.utils) {
				this.paging.count = 0
				this.tableData = []
				return
			}
			const { page, limit } = this.paging
			const { keyword } = this.search.data
			const { count, data } = this.paging.utils.getData({ page, limit, keyword, field: this.searchField })
			this.paging.count = count
			this.tableData = data
		},

		/** 切换分页数据量 */
		pagingSizeChange(limit) {
			this.paging.limit = limit
			this.getTableData()
		},

		/** 切换分页第几页 */
		pagingCurrentChange(page) {
			this.paging.page = page
			this.getTableData()
		},

		/** 导出错误 */
		async exportError(data) {
			const workbook = await createExcel({
				data,
				...this.exportErrorConfig
			})
			workbook.browser.downloadXLSX('错误数据')
		},

		/** 显示错误 */
		showError(msg = `共有 ${this.errorList.length} 条数据出现错误 !`) {
			MessageBox.confirm(msg, '消息提示', {
				showCancelButton: false,
				confirmButtonText: ' 确定',
				type: 'error'
			}).catch((err) => {
				console.error(err)
			})
		},

		/** 提交上传 */
		submit(data) {
			this.isSubmit = false
			const updateDataByExcelIndex = ($excelIndex, key, value) => {
				const i = $excelIndex === 0 ? 0 : $excelIndex - 1 - this.sliceLength
				const item = data[i]
				this.$set(item, key, value)
				return item
			}
			/** @type {import('./ctx').Ctx} */
			const ctx = {
				data,
				errorList: this.errorList,
				showError: this.showError.bind(this),
				updateData: updateDataByExcelIndex,
				updateDataByExcelIndex,
				updateDataByIndex: (i, key, value) => {
					const item = data[i]
					this.$set(item, key, value)
					return item
				},
				addError: (data) => {
					if (!(typeof data === 'object' && data !== null)) {
						console.error(data)
						throw new TypeError('设置的错误必须是一个对象数据')
					}
					this.errorList.push(cloneDeep(data))
					return this.errorList
				}
			}

			this.$emit('submit', ctx)
		}
	},

	created() {
		this.init()
	}
}
</script>

<style scoped lang="scss">
.hidden {
	display: none;
}
</style>
