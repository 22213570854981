/**
 * 生成文件 hash
 * @param {File|Blob} file File对象
 * @returns {Promise<string>}
 */
export default (file) => {
	return new Promise((resolve, reject) => {
		const reader = new FileReader()
		reader.onload = async ({ target }) => {
			try {
				const hash = await crypto.subtle.digest('SHA-256', target.result)
				const hashArr = Array.from(new Uint8Array(hash))
				const hashHex = hashArr.map((byte) => byte.toString(16).padStart(2, '0')).join('')
				resolve(hashHex)
			} catch (error) {
				reject(error)
			}
		}
		reader.readAsArrayBuffer(file)
	})
}
