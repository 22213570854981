<template>
	<view class="um-upload-fold" ref="UmFold" :style="style">
		<view class="um-upload-fold-content">
			<slot></slot>
		</view>
	</view>
</template>

<script>
	export default {
		model: {
			prop: 'state',
			event: 'change'
		},
		props: {
			state: {
				type: Boolean,
				default: false
			}
		},
		data() {
			return {}
		},

		computed: {
			style() {
				if (this.state) {
					return {
						'grid-template-rows': '1fr'
					}
				}

				return {
					'grid-template-rows': '0fr'
				}
			}
		},

		watch: {
			state: {
				async handler(state) {
					if (state) {
						this.$emit('change', state)
						return
					}
					this.$emit('change', state)
				}
			}
		}
	}
</script>

<style scoped lang="scss">
	.um-upload-fold {
		display: grid;
		--um-upload-fold-transition: 0.3s;
		transition: var(--um-upload-fold-transition);
		overflow: hidden;

		.um-upload-fold-content {
			min-height: 0;
		}
	}
</style>
