/**
 * 用于处理更加精细的权限控制
 */

import store from '@/store'

const getMap = () => {
	return {
		/** 项目详情/源码存档按钮 */
		'show/projectDetail/sourceCodeBackup': 'sourceCodeBackup',
		/** 项目详情/接口管理按钮 */
		'show/projectDetail/apiManage': 'apiManage',
		/** 项目详情/事件管理按钮 */
		'show/projectDetail/eventManage': 'eventManage',
		/** 项目详情/标签管理按钮 */
		'show/projectDetail/tagManage': 'tagManage',
		/** 项目详情/成员管理按钮 */
		'show/projectDetail/memberManage': 'memberManage',
		/** 项目详情/平台管理按钮 */
		'show/projectDetail/platformManage': 'platformManage',
		/** 项目详情/需求管理按钮 */
		'show/projectDetail/demandManage': 'demandManage'
	}
}

/**
 * 初始化权限映射表
 * @param {string[]} powerList 扁平化后的权限列表
 */
export const init = (powerList) => {
	const tempMap = getMap()
	const map = getMap()
	const keys = Object.keys(map)
	keys.forEach((key) => {
		map[key] = null
		powerList.forEach((power) => {
			if (tempMap[key] === power) {
				map[key] = tempMap[key]
			}
		})
	})

	store.dispatch('setPowerMap', map)
	return map
}
