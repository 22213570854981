import axios from 'axios'
import router from '../router'

const service = axios.create({
	timeout: 50000
})

service.interceptors.request.use(
	(config) => {
		return config
	},
	(error) => {
		console.log(error)
		return Promise.reject(error)
	}
)

service.interceptors.response.use(
	(response) => {
		if (response.status === 200) {
			// 登录过期
			if (response.data.code === -1) {
				localStorage.removeItem('ms_username')
				if (!['/', '/login'].includes(router.currentRoute.path)) {
					router.push('/login')
				}
			}
			return response.data
		} else {
			Promise.reject(response)
		}
	},
	(error) => {
		console.log(error)
		return Promise.reject(error)
	}
)

export default service
