<template>
	<div
		class="um-move-container"
		:style="{
			userSelect,
			'--um-lineWidth': lineWidth,
			'--um-lineBackground': lineBackground,
			'--um-lineHoverBackground': lineHoverBackground,
			'--um-lineTransitionBackground': lineTransitionBackground
		}"
	>
		<slot></slot>
		<div class="um-line" @mousedown="mousedown">
			<slot name="line">
				<div class="um-line-default"></div>
			</slot>
		</div>
	</div>
</template>

<script>
export default {
	model: {
		event: 'change',
		prop: 'width'
	},
	props: {
		width: {
			type: Number,
			required: true
		},

		min: {
			type: Number,
			default: 0
		},

		max: {
			type: Number
		},

		lineWidth: {
			type: String,
			default: '6px'
		},

		lineBackground: {
			type: String,
			default: '#dcdfe6'
		},

		lineHoverBackground: {
			type: String,
			default: '#606266'
		},

		lineTransitionBackground: {
			type: String,
			default: 'background-color 0.3s'
		}
	},
	data() {
		return {
			defaultWidth: 0,
			userSelect: 'auto',
			startMove: false,
			startPlaceX: 0,
			mousemove: (e) => {
				if (!this.startMove) {
					return
				}
				const deviation = e.clientX - this.startPlaceX
				this.callback(deviation)
			},
			mouseup: () => {
				this.startMove = false
				this.startPlaceX = 0
				this.defaultWidth = this.width
				this.userSelect = 'auto'
				document.removeEventListener('mousemove', this.mousemove)
				document.removeEventListener('mouseup', this.mouseup)
				this.$emit('stop', this.defaultWidth)
				this.$emit('change', this.defaultWidth)
			}
		}
	},

	methods: {
		mousedown(e) {
			if (e.button !== 0) {
				return
			}
			this.startMove = true
			this.userSelect = 'none'
			document.addEventListener('mousemove', this.mousemove)
			document.addEventListener('mouseup', this.mouseup)
			this.startPlaceX = e.clientX
			this.$emit('start', this.defaultWidth)
		},

		callback(deviation) {
			let newWidth = this.defaultWidth + deviation
			if (newWidth < this.min) {
				newWidth = this.min
			}

			if (this.max && newWidth > this.max) {
				newWidth = this.max
			}
			this.$emit('move', newWidth)
			this.$emit('change', newWidth)
		},

		update(width) {
			this.defaultWidth = width
		}
	},

	created() {
		this.defaultWidth = this.width
	}
}
</script>

<style scoped lang="scss">
.um-move-container {
	position: relative;
	display: flex;
	flex-wrap: wrap;

	.um-line {
		&:hover {
			cursor: col-resize;
			user-select: none;
		}

		.um-line-default {
			width: var(--um-lineWidth);
			height: 100%;
			background: var(--um-lineBackground);
			transition: var(--um-lineTransitionBackground);

			&:hover {
				background: var(--um-lineHoverBackground);
			}
		}
	}
}
</style>
