/**
 * ArrayBuffer 生成 hash
 * - 只在 https 或 localhost 环境中生效
 * @param {ArrayBuffer} buf File对象
 * @param {boolean} development 是否启用开发环境
 * - 启用后即使不在  https 或 localhost 环境中依然可使用
 * - 内部使用 development && process.env.NODE_ENV === 'development' 条件进行判断, 不影响生产环境
 * @returns {Promise<string>}
 */
export const createHash = async (buf, development) => {
	if (!(location.hostname === 'localhost' || location.protocol === 'https:')) {
		if (development && process.env.NODE_ENV === 'development') {
			return `development-${Math.random() * Date.now()}-${Date.now()}`
		}
		throw new Error('hash 算法 只在 https 或 localhost 环境中生效')
	}
	const hash = await crypto.subtle.digest('SHA-256', buf)
	const hashArr = Array.from(new Uint8Array(hash))
	return hashArr.map((byte) => byte.toString(16).padStart(2, '0')).join('')
}

/**
 * 获取文件 hash
 * @param {File|Blob} file File|Blob 对象
 * @param {boolean} development 是否启用开发环境
 * - 启用后即使不在  https 或 localhost 环境中依然可使用
 * - 内部使用 development && process.env.NODE_ENV === 'development' 条件进行判断, 不影响生产环境
 * @returns {Promise<string>}
 */
export const getFileHash = (file, development) => {
	return new Promise((resolve, reject) => {
		const reader = new FileReader()
		reader.onload = async ({ target }) => {
			try {
				const hash = await createHash(target.result, development)
				resolve(hash)
			} catch (error) {
				reject(error)
			}
		}
		reader.readAsArrayBuffer(file)
	})
}

/**
 * 获取文件名扩展名, 包含小数点, 如 .png
 * - api 接口内部调用
 * @param {string} filename 文件名
 */
export const getExt = (filename) => {
	const i = filename.lastIndexOf('.')
	if (i === -1) return ''
	return filename.substring(i)
}
